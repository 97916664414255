<template>
    <AppContainer>
        <Appointments/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import Appointments from '@/components/dashboard/booking/Appointments.vue'
export default {
  name: 'appointmentsList',
  components: {
    AppContainer,
    Appointments
  }
}
</script>