<template>
    <div class="modal fade large" id="bookingDetailModal" tabindex="-1" role="dialog" aria-labelledby="bookingDetailModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="bookingDetailModalLabel"><span></span>Booking Detail</h5>
                    <span title="Close">
                        <a class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">
                                <img class="img-fluid" src="@/assets/images/dashboard/close.svg" alt="" />
                            </span>
                        </a>
                    </span>
                </div>
                <div class="modal-body" v-if="!props.loading && booking_detail.id">
                    <div class="row">
                        <div class="col-6">
                            <div class="row border-bottom pb-3">
                                <div class="col-12">
                                    <h4 class="fw-bold mb-3">User</h4>
                                </div>
                                <div class="col-3 pe-0">
                                    <img src="@/assets/images/profile/Anne_Hathaway_Face.jpg" class="img-fluid rounded-circle w-75" alt="UserImage"/>
                                </div>
                                <div class="col-9 p-0 mt-1">
                                    <h6 class="fw-bold fs-5">{{booking_detail.user.name}}</h6>
                                    <h6>{{booking_detail.user.email}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row border-bottom pb-3">
                                <div class="col-12">
                                    <h4 class="fw-bold mb-3">Provider</h4>
                                </div>
                                <div class="col-3 pe-0">
                                    <img src="@/assets/images/profile/Anne_Hathaway_Face.jpg" class="img-fluid rounded-circle w-75" alt="UserImage"/>
                                </div>
                                <div class="col-9 p-0 mt-1">
                                    <h6 class="fw-bold fs-5">
                                        {{booking_detail.provider.name}}
                                    </h6>
                                    <h6>
                                        {{booking_detail.provider.email}}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <ul class="list-group pt-3">
                                <li class="list-group-item border-0">
                                    <span class="fw-bold">Date:</span>
                                    {{booking_detail.booking_date}}
                                </li>
                                <li class="list-group-item border-0">
                                    <span class="fw-bold">Phone:</span>
                                    {{booking_detail.user_phone}}
                                </li>
                                <li class="list-group-item border-0">
                                    <span class="fw-bold">Tracking_ID:</span>
                                    {{booking_detail.tracking_id}}
                                </li>
                                <li class="list-group-item border-0">
                                    <span class="fw-bold">Payment:</span>
                                    ${{booking_detail.booking_price}}
                                </li>
                                <li class="list-group-item border-0">
                                    <span class="fw-bold">Address:</span>
                                    {{booking_detail.user_address}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 pt-3">
                            <h4 class="fw-bold mb-3">Covid Points</h4>
                            <ul>
                                <li class="" v-for="cp in booking_detail.boooking_covid_points" :key="cp.id">
                                    <div class="row pb-2">
                                        <div class="col-10 bold">{{cp.point}}</div>
                                        <div class="col-2 fw-bold">{{cp.answer}}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h4 class="fw-bold mt-4">Services</h4>
                        </div>
                        <div class="col-12 mt-3">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Title</th>
                                        <th class="text-center">Duration</th>
                                        <th class="text-center">Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="service in booking_detail.boooking_services" :key="service.id">
                                        <td>{{service.booking_id}}</td>
                                        <td>{{service.title}}</td>
                                        <td class="text-center">{{service.duration}} min</td>
                                        <td class="text-center">${{service.cost}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colspan="3" class="text-right fw-bold">Total:</th>
                                        <th class="text-center fw-bold">${{servicesTotal}}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-center p-5" v-if="props.loading">
                    <div class="spinner-border text-muted"></div>
                </div>
                <div class="modal-footer" >
                    <a href="#" class="add-btn" data-bs-dismiss="modal" aria-label="Close">
                        Close
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed } from "vue";
const props = defineProps({ 
    booking_detail: Object,
    loading: {
        type: Boolean,
        default: false
    }
});

const servicesTotal = computed(() => {
    if (props.booking_detail?.boooking_services?.length > 0) {
        return props.booking_detail.boooking_services.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0).toFixed(2);
    }
    return 0;
})
</script>
