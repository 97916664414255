<template>
<div>

    <a class="refresh btn-block btn hidde" id="paymentReaderModal" data-bs-toggle="modal" data-bs-target="#paymentModal"  href="javascript:;">Register Reader</a>
    <!-- Payment Loader -->

    <div class="payment-overlay" v-if="loaderProcessing" >
      <div class="payment-loader">
        <div class="loader"></div>
        <div class="message">Please swipe your card on the reader</div>
      </div>
    </div> 

   
    
    <!-- ounter-grid -->
    <div class="counter-grid">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="counters" :class="$route.params.type == 'queue' || $route.params.type == '' ? 'active':''" v-on:click="updateData('queue')">
                    <div class="thamnils">
                        <img class="img-fluid" src="../../../assets/images/icons/live_queue.svg" alt="">
                    </div>
                    <div class="counter-num">
                        <p>Today Queues</p>
                        <h4>{{apptCounters.today_queues}}</h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="counters" :class="$route.params.type == 'today' ? 'active':''" v-on:click="updateData('today')">
                    <div class="thamnils">
                        <img class="img-fluid" src="../../../assets/images/icons/today_appointments.svg" alt="">
                    </div>
                    <div class="counter-num">
                        <p>Today Appointments</p>
                        <h4>{{apptCounters.today_appointments}}</h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="counters" :class="$route.params.type == 'upcoming' ? 'active':''" v-on:click="updateData('upcoming')">
                    <div class="thamnils">
                        <img class="img-fluid" src="../../../assets/images/icons/future_appointment.svg" alt="">
                    </div>
                    <div class="counter-num">
                        <p>Fututure Appointments</p>
                        <h4>{{apptCounters.future_appointments}}</h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="counters" :class="$route.params.type == 'past' ? 'active':''" v-on:click="updateData('past')">
                    <div class="thamnils">
                        <img class="img-fluid" src="../../../assets/images/icons/past_appointment.svg" alt="">
                    </div>
                    <div class="counter-num">
                        <p>Past Appointments</p>
                        <h4>{{apptCounters.past_appointments}}</h4>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- ounter-grid -->
   
    <!-- Appointments-table-area  -->
    <div class="leads-table-area addcustomer-table addcampaigm-table" v-show="$route.params.type != 'queue' && $route.params.type != ''">
        <table id="appt_table" class="display appt_table">
            <thead>
                <tr>
                    <th>ID</th>
                    <!-- <th>Created Date</th> -->
                    <th>Customer</th>
                    <th>Provider</th>
                    <th>Price</th>
                    <th>Duration</th>
                    <th style="width:70px">Status</th>
                    <th>Schedule</th>
                    <th>Action</th>
                    <th style="width:40px"></th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- Appointments-table-area  -->

    <!-- Queue-area -->
    <div class="desking-area" v-if="$route.params.type == 'queue' || $route.params.type == ''">
        <!-- desking-box -->
        <div class="desk-mid-box desk-setting-mid">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" v-for="(item,index) in queues" :key="index">
                    <a class="nav-link" :class="index == 0 ? 'active':''" :id="'queuePerson-tab'+index" data-bs-toggle="tab" :href="'#queuePerson'+index" role="tab" :aria-controls="'queuePerson'+index">
                        <img class="img-fluid mr-2" style="width:40px;vertical-align: middle;" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" >
                        {{item.user.name}}
                    </a>
                </li>
                <!-- <li class="nav-item" role="presentation">
                    <a class="nav-link" id="Taxes-tab" data-bs-toggle="tab" href="#Taxes" role="tab" aria-controls="Taxes" aria-selected="false"><img class="img-fluid mr-2" style="width:40px;vertical-align: middle;" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" >Muhammad Usman</a>
                </li> -->
            </ul>
            <div class="tab-content">
                <div v-for="(item,index) in queues" :key="index" class="tab-pane fade" :class="index == 0 ? 'show active':''" :id="'queuePerson'+index" role="tabpanel" :aria-labelledby="'queuePerson-tab'+index">
                    <div class="queue-content-area">
                        <div class="row" v-if="item.activeQueues.length > 0">
                            <div class="col-12">
                                <div class="detais-title">
                                    <h3>Active Queue</h3>
                                </div>
                            </div>
                            <div class="col-md-8 col-lg-8">
                                <div class="row Taxes-content">
                                    <div class="col-sm-4 pt-5 queueWrapper">

        
                                        <button  class="rem-btn success-btn float-right mt-5 " v-if="item.activeQueues[0].booking_status == 0  && item.activeQueues[0].booking_source === 'walkin'" 
                                        v-on:click="queueWalkin(item.activeQueues[0].id,'start','queue')"
                                        ><i class="fa fa-check" style="font-size: 16px;"></i>Start</button>

                                        <button class="rem-btn success-btn float-right mt-5" v-else-if="item.activeQueues[0].booking_status == 0" v-on:click="updateBookingStatus(item.activeQueues[0].id,'start','queue')"><i class="fa fa-check" style="font-size: 16px;"></i>Start</button>

                                        <button class="rem-btn warning-btn mt-5 float-right" v-if="item.activeQueues[0].booking_status == 1">
                                            <div class="spinner-grow text-secondary" style="width: 14px;height: 14px;" role="status">
                                                <span class="visually-hidden">Processing...</span>
                                            </div>Processing..
                                        </button>
                                    </div>
                                    <div class="col-sm-4 text-center">
                                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" style="max-height: 120px;">
                                        <h4>{{item.activeQueues[0].user_firstname}} {{item.activeQueues[0].user_lastname}}</h4>
                                    </div>
                                    <div class="col-sm-4 pt-5">
                                        <button class="add-btn mt-5 danger-btn float-left" v-if="item.activeQueues[0].booking_status == 0" v-on:click="updateBookingStatus(item.activeQueues[0].id,'cancel','queue')"><i class="fa fa-times" style="font-size: 16px;"></i>Cancel</button>
                                        <button class="add-btn mt-5 ml-1 float-left" v-if="item.activeQueues[0].booking_status == 0" v-on:click="updateBookingStatus(item.activeQueues[0].id,'no-show','queue')"><i class="fa fa-times" style="font-size: 16px;"></i>No Show</button>
                                        <button class="rem-btn success-btn float-left mt-5" v-if="item.activeQueues[0].booking_status == 1" v-on:click="updateBookingStatus(item.activeQueues[0].id,'complete','queue')"><i class="fa fa-check" style="font-size: 16px;"></i>Complete</button>
                                    </div>
                                    <div class="col-12 pt-3">
                                        <p>Overall progress:</p>
                                        <div class="progress">
                                            <div class="progress-bar bg-success" v-bind:style="{ width: item.activeQueues[0].overAllProgress > 100 ? '100%':item.activeQueues[0].overAllProgress+'%'}">
                                                <span v-if="item.activeQueues[0].overAllProgress > 100">100%</span>
                                                <span v-else>{{item.activeQueues[0].overAllProgress}}%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="fw-bolder text-muted" v-if="item.activeQueues[0].overAllProgress < 40">Starting…</div>
                                        <div class="fw-bolder text-muted" v-else-if="item.activeQueues[0].overAllProgress < 70">Awesome…</div>
                                        <div class="fw-bolder text-muted" v-else>Rounding up…😊</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <div class="detais-title">
                                    <h3>Services</h3>
                                </div>
                                <div class="row">
                                    <div class="col-12" v-for="serv in item.activeQueues[0].boooking_services" :key="serv.id">
                                        <div class="title d-inline-block w-50">{{serv.title}}</div>
                                        <div class="value d-inline-block text-right w-50">${{serv.cost}}
                                            <span class="text-muted small">({{serv.duration}} min)</span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12 mt-1">
                                        <div class="progress" style="height: 10px;">
                                            <div class="progress-bar bg-success" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="item.activeQueues.length > 1">
                            <div class="col-12 mt-4">
                                <div class="detais-title">
                                    <h3>Next in Queue</h3>
                                </div>
                                <div class="todayqueuewraper">

                                    <div class="queue-arousel" v-if="item.activeQueues.length > 0">
                                          <Carousel :items-to-show="3" :itemsToScroll="3" :breakpoints="breakpoints" class="queues-arousel">
                                                <Slide v-for="(queue,index) in item.activeQueues.slice(1)" :key="index">
                                                    <div class="item">
                                                        <div class="pad15">
                                                            <div class="bk-image">
                                                                <img src="@/assets/images/icons/user-icon.jpg" />
                                                            </div>
                                                            <div class="queuetime" style="background:url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/queuetime.png') no-repeat top"> {{
                                                                changeTimeFormate(queue.booking_date+' '+queue.booking_start_time,12)}}
                                                            </div>

                                                            <div class="booking-time">O{{index+1}}</div>
                                                            <div class="booking-id"> {{queue.user_firstname}} </div>
                                                        </div>
                                                    </div>
                                                </Slide>
                                                <template #addons>
                                                <Navigation />
                                                </template>
                                            </Carousel>


                                        <!-- <Carousel :items-to-show="3" :itemsToScroll="3" :breakpoints="breakpoints" >
                                            <Slide v-for="(queue,index) in item.activeQueues.slice(1)" :key="index">
                                                <div class="queuelistingwrp">
                                                    <div class="queuetime" style="background:url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/queuetime.png') no-repeat top"> {{
                                                        changeTimeFormate(queue.booking_date+' '+queue.booking_start_time,12)}}
                                                    </div>
                                                    <div class="queueimglist" style="background-image:url(https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg);">  &nbsp; </div>
                                                    <h3>{{queue.user_firstname}}</h3>
                                                </div>
                                            </Slide>

                                            <template #addons>
                                            <Navigation />
                                            </template>
                                        </Carousel> -->
                                    </div>


                                    <!-- <h4>Next in Queue</h4> -->
                                    <!-- <ul>
                                        <li class="activequeue">
                                            <div class="queuelistingwrp">
                                                <div class="queuetime" style="background:url({{base_url}}assets/images/queuetime.png) no-repeat top">  10:30am  </div>
                                                <div class="queueimglist" style="background:url({{base_url}}assets/images/queueimg4.jpg) no-repeat top;">  &nbsp; </div>
                                                <h3>Muhammad Usman</h3>
                                            </div>
                                        </li>
                                        <div class="clearfix"></div>
                                    </ul> -->
                                    <!-- <p ng-if="all_queues.length < 2">No other queue is available.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-2" v-if="item.activeQueues.length == 0">
                            <h6>No one is in queue.</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leads-table-area py-4 no-queue"  v-if="queues.length == 0 ">
                <div class="text-center mt-2"  v-if="this.user_role == 'business'">
                    <h6>You don't have any employee in queue.</h6>
                    <router-link :to="{name:'Employees'}" class="rem-btn">Manage Employees</router-link>
                </div>
                <div class="text-center mt-2"  v-else>
                    <h6>You don't have any queue.</h6>
                </div>
            </div>

        </div>
    </div>
    <!-- Queue-area -->

    <!-- Queue-area -->
    <!-- <div class="graph-area" v-if="$route.params.type == 'queue'">
        <div class="grap-title">
            <div class="title-grap">
                <h4><img class="img-fluid mr-2" style="width:40px;vertical-align: middle;" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" >Ondaq Admin</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card rounded-0 mb-0">
                    <div class="card-header queue-header ">
                        <h4 class="pull-left mt-1 mb-0">Active Queue</h4>
                    </div>
                    <div class="card-body queue-wrapper">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="active-queue row">
                                    <div class="col-sm-8 text-center pr-5 pl-5">
                                        <div class="row">
                                            <div class="col-sm-4 mt-5">
                                                <button class="btn btn-primary btn-circle btn-xl pull-right"><i class="fa fa-check" style="font-size: 16px;"></i><br>Pending</button>

                                                <button class="btn btn-warning btn-circle btn-xl pull-right" ><i class="fa fa-circle-o-notch fa-spin" style="font-size: 16px;"></i><br>Processing..</button>
                                            </div>
                                            <div class="col-sm-4">
                                                <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" style="max-height: 120px;">
                                            </div>
                                            <div class="col-sm-4 mt-5">
                                                <button class="btn btn-danger btn-circle btn-xl pull-left"><i class="fa fa-times" style="font-size: 16px;"></i><br>Cancel</button>
                                            </div>
                                        </div>
                                        <h4>Muhammad Usman </h4>
                                        <div class="progress">
                                            <div class="progress-bar bg-success" style="width:50%">
                                                50%
                                            </div>
                                            <div class="progress-bar bg-success" style="width:100%">
                                            100%
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <h4 class="text-left">Services</h4>
                                        <ul class="horizontal-bars type-2">
                                            <li>
                                                <span class="title">Hair Cut</span>
                                                <span class="value">$10.00
                                                    <span class="text-muted small">(30 min)</span>
                                                </span>
                                                <div class="bars">
                                                    <div class="progress progress-xs">
                                                        <div class="progress-bar bg-success" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="todayqueuewraper">
                                        <h4>Next in Queue</h4>
                                        <ul>
                                            <li class="activequeue">
                                                <div class="queuelistingwrp">
                                                    <div class="queuetime" style="background:url({{base_url}}assets/images/queuetime.png) no-repeat top">  10:30am  </div>
                                                    <div class="queueimglist" style="background:url({{base_url}}assets/images/queueimg4.jpg) no-repeat top;">  &nbsp; </div>
                                                    <h3>Muhammad Usman</h3>
                                                </div>
                                            </li>
                                            <div class="clearfix"></div>
                                        </ul>
                                        <p ng-if="all_queues.length < 2">No other queue is available.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 ng-if="all_queues.length == 0" style="padding: 15px;">
                            No user found with in Queue.
                        </h5>
                    </div>
                </div>
            </div>
        </div>

    </div> -->
    <!-- Queue-area -->

    <!-- Modal -->
    <div id="container">
        <BookingDetailModal :booking_detail="booking_detail" :loading="loading" />
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <!-- Start main body coding  -->
                        <div class="row">
                            <div class="col-sm-12 col-md-4 ">
                                <div class=" text-center">
                                    <img src="@/assets/images/business_logo/default.jpg" />
                                </div>
                                <h6 class="fw-normal col-sm-12 text-center mt-3 ">Video Traning Account</h6>
                            </div>
                            <div class=" col-md-8">
                                <!-- start row one -->
                                <div class=" row mt-3 ">
                                    <div class="col-sm-6 col ">
                                        <p class="mb-2 ">Your Overall Review</p>
                                        <div class="text-warning  ">
                                           <star-rating  v-model:rating="review_.overAll"  :rating="$route.query.rating"  :star-size="25" :show-rating="false"></star-rating>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col ">
                                        <p class="mb-2">Punctuality</p>
                                        <div class="text-warning ">
                                           <star-rating v-model:rating="review_.punctuality" :rating="$route.query.rating"  :star-size="25" :show-rating="false"></star-rating>
                                        </div>
                                    </div>
                                </div>
                                <!-- start row 2  -->
                                <div class="row mt-3">
                                    <div class="col-sm-6 col ">
                                        <p class="mb-2">Values</p>
                                        <div class="text-warning mb-3">
                                            <star-rating  v-model:rating="review_.values" :rating="$route.query.rating" :star-size="25" :show-rating="false"></star-rating>
                                            <!-- <ul class="results-rating">
                                                <li class="list-inline-item m-0">
                                                <fa icon="star" />
                                                </li>
                                                <li class="list-inline-item m-0">
                                                <fa icon="star" />
                                                </li>
                                                <li class="list-inline-item m-0">
                                                <fa icon="star" />
                                                </li>
                                                <li class="list-inline-item m-0">
                                                <fa icon="star" />
                                                </li>
                                                <li class="list-inline-item m-0">
                                                <fa :icon="['far','star']" />
                                                </li>
                                            </ul> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col">
                                        <p class="mb-2">Services</p>
                                        <div class="text-warning">
                                            <star-rating v-model:rating="review_.services" :rating="$route.query.rating"  :star-size="25" :show-rating="false"></star-rating>
                                            <!-- <ul class="results-rating">
                                                <li class="list-inline-item m-0">
                                                <fa icon="star" />
                                                </li>
                                                <li class="list-inline-item m-0">
                                                <fa icon="star" />
                                                </li>
                                                <li class="list-inline-item m-0">
                                                <fa icon="star" />
                                                </li>
                                                <li class="list-inline-item m-0">
                                                <fa icon="star" />
                                                </li>
                                                <li class="list-inline-item m-0">
                                                <fa :icon="['far','star']" />
                                                </li>
                                            </ul> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- start row 3 -->

                                <div class="border-top">
                                    <p class="mt-3 mb-1 mb-0 p-0  ">Leave a review for a business:</p>
                                    <textarea class="form-control col " style="min-height: 130px" id="message-text" v-model="review_.businessReview"></textarea>
                                </div>
                                <!-- start row 4 -->
                                <div class=" ">
                                    <p class="mt-3 mb-1 mb-0 p-0 ">Leave a review for Bryan Nguyen:</p>
                                    <textarea class="form-control col " style="min-height: 130px"   id="message-text" v-model="review_.userReview"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-0 ">
                            <button type="button" class="btn btn-danger btn-lg" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-success btn-lg" v-on:click="review()">Post Review</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Payment Modal -->
    <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="paymentModal" aria-hidden="true" >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Walk-in Payment</h3>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-12">
                                <h5 class="mb-2">Choose Card Reader</h5>
                            </div>
                            <div class="col-md-6 "  v-for="(reader,index) in readers" :key="index"  >
                                <div class="card emp-card" v-on:click="getSummary(reader)" :class="walkin_Payment.reader.id == reader.id ? 'active':''">
                                    <div class="card-body p-3 mt-0">
                                        <div class="booking-vendor-img" >
                                            <img class="img-fluid" src="../../../assets/images/wisepos.png" alt="">
                                        </div>
                                        <div id="DIV_10">
                                            <a href="javascript:void(0);" id="A_11">{{reader.label}}</a>
                                            <div id="DIV_12">
                                                <span  class=" badge badge-success" >{{reader.status}}</span>  <br>
                                                <span id="SPAN_14" >{{reader.id}}</span>            
                                            </div>
                                        </div>
                                    </div>
                                    <fa icon="check-circle" class="fa-lg" />
                                </div>
                            </div>
                        </div>

               
                            
                    </div>
                    <div class="modal-footer">
                        <a href="#" data-bs-dismiss="modal" aria-label="Cancel" class="add-btn">Cancel</a>
                        <a href="#" v-on:click.prevent="proceedToPayment" class="rem-btn">Proceed</a>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { useToast } from "vue-toastification";
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from "moment";
import StarRating from 'vue-star-rating'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getOrderDetails } from "@/components/booking/utils"
import BookingDetailModal from '@/components/booking/modals/BookingDetailModal';

export default {
    name: "Appointments",
    setup () {
        const toast = useToast();
        return { v$: useVuelidate(),toast }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        StarRating,
        BookingDetailModal
    },
    data() {
        return {
            booking_detail: {},
            loading: false,
            review_:{
                businessReview: '',
                userReview: '',
                services: 0

                },
            bookingType:2,
            queues:[],
            apptCounters:{
                future_appointments:0,
                past_appointments:0,
                past_queues:0,
                today_appointments:0,
                today_queues:0
            },
            breakpoints: {
                575: {
                    itemsToShow: 4,
                    itemsToScroll: 1,
                    snapAlign: 'start',
                },
                // 700px and up
                992: {
                    itemsToShow: 5,
                    itemsToScroll: 1,
                    snapAlign: 'start',
                },
                // 1024 and up
                1200: {
                    itemsToShow: 7,
                    itemsToScroll: 1,
                    snapAlign: 'start',
                }
            },
            user_role:"",
            loaderProcessing: false,
            readers:[],
            walkin_Payment:{
                booking_id:"",
                booking_type:"",
                reader:{},
                booking_source:'walkin',
                business_id: this.$storage.getStorageSync('business').active_business.id,
                paymentIntentId:null
            },
            capturePayment : false,
            intervalId: null,

        };
    },
    validations () {
        return {
            review_:{
                businessReview:{ required },
                services:{ required }
            }
        }
    },
    created() {
        this.getBookingCounter()
        this.getQueus();
    },
    methods: {
        getQueus(){
            let thiss = this;
            axios.get('queues/'+this.$storage.getStorageSync('business').active_business.id).then(function (response) {
                console.log(response.data.data)
                if (response.data.status == 'success') {
                    thiss.queues = response.data.data;
                    console.log(thiss.queues[0]);
                    if(thiss.queues.length>0){
                        thiss.queues.forEach((item) => {
                            var status= item.activeQueues[0].booking_status;
                            if( status == 1){
                                let startTime = new Date(item.activeQueues[0].booking_start_date);
                                let totalTime =item.activeQueues[0].booking_duration;
                                setInterval(function () {
                                    item.activeQueues[0].overAllProgress = thiss.progressBar(startTime,totalTime);
                                    console.log(thiss.progressBar(startTime,totalTime));
                                    }, 1000);
                            }
                        });
                    }

                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        thiss.toast.error(error.response.data.message);
                    }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }else if (error.response.status == 401) {
                        thiss.toast.error(error.response.data.message,{position: "top-right"});
                    }
                }
            });
        },
        updateBookingStatus(id,status,type='booking'){
            console.log("Update Booking")
            let thiss = this
            let msg = "";
            if (status == 'no-show') {
                msg = "You don't meet this customer."
            }else if (status == 'start') {
                msg = "You want to start this booking!"
            }else if(status == 'cancel'){
                msg = "You want to cancel this booking!"
            }else if(status == 'complete'){
                msg = "This appointment is successfully completed!"
            }


            thiss.$swal({
                title: 'Are you sure?',
                text: msg,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: false,
                customClass: {
                    confirmButton: 'rem-btn mr-3',
                    cancelButton: 'add-btn'
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.isConfirmed) {
                    thiss.updateBookingConfirmed(id,status,type)
                }
            })

        },
        updateBookingConfirmed(id,status,type='booking'){
            let thiss = this
            axios.post('update_booking_status',{booking_id:id,status:status}).then(function (response) {
                        if (response.data.status == 'success') {
                            if (type == 'queue') {
                                thiss.getQueus();
                            }else{
                                $("#appt_table").dataTable().fnDraw();
                                thiss.toast.success(response.data.message);
                            }
                        }
                    })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        thiss.toast.error(error.response.data.message);
                    }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }else if (error.response.status == 401) {
                        thiss.toast.error(error.response.data.message,{position: "top-right"});
                    }else{
                        thiss.toast.error(error.response.data.message);
                    }
                }
            });
        },
        updateData(value) {
            if(value != 'queue'){
                $("#appt_table").dataTable().fnDraw();
            }
            this.$router.push({ name: 'appointments', params: { type: value } })
        },
        changeTimeFormate(time,frmt){
            if (frmt == 12) {
                return moment(new Date(time)).format("hh:mm a");
            }else if (frmt == 24) {
                return moment(new Date(time)).format("HH:mm");
            }else{
                return moment(new Date(time)).format(frmt);
            }
        },
        progressBar(startTime,percentage) {
            let currentTime = new Date();
            var timeDiff = (currentTime.getTime() - startTime.getTime()) / 1000;
            var mint = timeDiff / 60;
            var per = (mint / percentage) * 100;
            if(per > 100){
                per = 100;
            }
           return Math.abs(Math.round(per));
        },
        getBookingCounter(){
            let thiss = this;
            axios.get('bookings_counter/'+this.$storage.getStorageSync('business').active_business.id).then(function (response) {
                console.log(response.data.data)
                if (response.data.status == 'success') {
                    thiss.apptCounters = response.data.data;
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        thiss.toast.error(error.response.data.message);
                    }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }else if (error.response.status == 401) {
                        thiss.toast.error(error.response.data.message,{position: "top-right"});
                    }
                }
            });
        },
        review(){
            const isFormValid = this.v$.review_.$validate()
            if (isFormValid) {
                console.log(this.review_)
                let thiss = this
                axios.post('give_review',this.review_).then(function (response) {
                    if (response.data.status == 'success') {
                        $("#exampleModal").modal("hide");
                    }else if (response.data.status == 'login') {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            }
        },

        getReaders(){
                let thiss = this
                axios.get('reader_list').then(function (response) {
                    thiss.readers = response.data.data
                    // console.log(thiss.employees)
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
        },
        getSummary(reader){
                this.walkin_Payment.reader = reader
        },
        proceedToPayment()
        {
            console.log("Proceed")
            $("#paymentModal").modal("hide");
            this.loaderProcessing = true;
            let thiss = this
                axios.post('payment_intent',this.walkin_Payment).then(function (response) {
                   
                    console.log("testtttttt12",response.data.data)
                    if (response.data.status === 'success') {
                        const action = response.data.data.action;
                        
                        if (action && action.status === "in_progress" && action.process_payment_intent) {
                            thiss.walkin_Payment.paymentIntentId = action.process_payment_intent.payment_intent;
                            thiss.capturePayment = true;
                            //$("#exampleModal").modal("hide");
                        } else {
                            console.log("Payment action is not in progress ",action);
                            this.toast.error("Payment action is not in progress or no payment intent found");
                        }
                    } else if (response.data.status === 'login') {
                        // Handle login redirection or logout
                        this.$storage.removeStorageSync("user");
                        this.$storage.removeStorageSync("token");
                        this.$storage.removeStorageSync("business");
                        this.$router.push({ name: 'Home' });
                    } else {
                        this.toast.error("Timeout! Your payment is not successfull");
                        console.log("Unexpected response status:", response.data.status);
                    }

                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                    this.loaderProcessing = false;
                });

        },
        // Function to call API
        capturePaymentIntent() {
            console.log("call capturePaymentIntent")
            let thiss = this
                axios.post('capture_payment_intent',this.walkin_Payment).then(function (response) {

                   
                    if (response.data.status === 'success') {

                        if(response.data.data.status == "succeeded"){
                            thiss.capturePayment = false;
                            thiss.loaderProcessing = false;
                            thiss.stopInterval()

                            thiss.$swal({
                                title: 'Payment Successful!',
                                text: 'Your payment has been successfully completed.',
                                icon: 'success', // Change icon to success
                                customClass: {
                                    confirmButton: 'rem-btn mr-3',
                                    cancelButton: 'add-btn'
                                },
                                buttonsStyling: false
                            });

                            thiss.updateBookingConfirmed(thiss.walkin_Payment.booking_id,"start",thiss.walkin_Payment.booking_type)
                           
                        }
                       
                    } else if (response.data.status === 'login') {
                        // Handle login redirection or logout
                        this.$storage.removeStorageSync("user");
                        this.$storage.removeStorageSync("token");
                        this.$storage.removeStorageSync("business");
                        this.$router.push({ name: 'Home' });

                    } else {
                        thiss.capturePayment = false;
                        thiss.loaderProcessing = false;
                        thiss.stopInterval()
                        this.toast.error("Unexpected response");
                        console.log("Unexpected response status:", response.data.status);
                    }
                    
                })
                .catch(error => {

                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                    thiss.capturePayment = false;
                    thiss.loaderProcessing = false;
                    thiss.stopInterval()
                   
                });
        
           
        },
        // Function to start the interval
        startInterval() {
            this.intervalId = setInterval(this.capturePaymentIntent, 5000); // Start the interval
        },
        // Function to stop the interval
        stopInterval() {
            clearInterval(this.intervalId); // Stop the interval
        },

        queueWalkin(booking_id) {
           
            this.walkin_Payment.booking_id = booking_id
            this.walkin_Payment.booking_type = "queue";
            // let status = $(this).data("status");
            // let booking_source = "walkin"

            // $("#paymentReaderModal").trigger("click");
            
            this.getReaders()
            $("#paymentModal").modal("show");
        }
    },
    mounted() {
        this.user_role = this.$storage.getStorageSync('role')
        let thiss = this;
        $("#appt_table").dataTable({
            //order: [[7, "desc"]],
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
                url: axios.defaults.baseURL + "dt_appointments/business/"+this.$storage.getStorageSync("business").active_business.id,
                type: "POST",
                data: function (d) {
                    d.duration = thiss.$route.params.type;
                },
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                },
            },
            drawCallback: function () {
                //alert( 'Table redrawn '+new Date() );
            },
            columnDefs: [
                {
                    targets: [3, 4, 5, 7, 8],
                    orderable: false,
                },
            ],
            //columnDefs: [{ targets: [-1, -3], className: 'dt-body-right' }]
        });

        // $(".past_queues_table").dataTable({
        //     responsive: true,
        //     fixedHeader: true,
        //     processing: false,
        //     serverSide: false,
        // });

        $("tbody").on("click", ".walkin-booking", function (e) {
            e.preventDefault();
           
            let booking_id = $(this).data("id");
            thiss.walkin_Payment.booking_id = booking_id
            thiss.walkin_Payment.booking_type = "booking";
            
            thiss.getReaders()
            $("#paymentModal").modal("show");

            // thiss.updateBookingStatus(booking_id,status,"booking",booking_source)
        });

        $(".queueWrapper").on("click", ".walkin-booking", function (e) {
            e.preventDefault();

            console.log("clicked");
           
            let booking_id = $(this).data("id");
            thiss.walkin_Payment.booking_id = booking_id
            // let status = $(this).data("status");
            // let booking_source = "walkin"

            // $("#paymentReaderModal").trigger("click");

            thiss.getReaders()
            $("#paymentModal").modal("show");

            // thiss.updateBookingStatus(booking_id,status,"booking",booking_source)
        });

        

        $("tbody").on("click", ".change-status", function (e) {
            e.preventDefault();
            let booking_id = $(this).data("id");
            let status = $(this).data("status");
            thiss.updateBookingStatus(booking_id,status,"booking")
        });

        $("tbody").on("click", ".viewOrderDetail", async function (e) {
            e.preventDefault();
            let booking_id = $(this).data("id");
            if(booking_id){
                $("#bookingDetailModal").modal("show");
                thiss.loading = true
                thiss.booking_detail = await getOrderDetails(booking_id)
                thiss.loading = false
            }
        });
        
    },
    watch:{
        capturePayment(newVal){
            if(newVal)
            {
                this.startInterval();
            }else{
                this.stopInterval();
            }
        },
        
        "$route.params.type": function () {
            if (this.$route.params.type != 'queue') {
                $("#appt_table").dataTable().fnDraw();
            }
        }
    }
};
</script>

<style scoped>
/* Queue */
.counter-num h4 {
    font-size: 22px;
    color:#000
}
.no-queue a.rem-btn{
    display: inline-block;
    line-height: unset;
    margin-top: 30px;
    margin-bottom: 30px;
}
.queue-content-area{
    padding: 20px;
}

.counters.active{
    background: #6e317a;
}
.counters.active .counter-num p, .counters.active .counter-num h4{
    color: #fff
}
/* .desk-mid-box .nav-tabs .nav-link.active{
    background: #6e317a !important;
    color: #fff !important;
}
.desk-mid-box .nav-tabs .nav-link{
    color: #6e317a !important;
    background: #fff !important;

} */
.dataTables_wrapper{
    overflow: inherit !important
}
</style>
<style type="text/css">
    table.appt_table tbody tr:hover .order-view-btn{
        display: block;
    }
    table.appt_table .order-view-btn{
        display: none;
    }

    .todayqueuewraper {
        padding: 40px 5px;
        width: 100%;
    }
    .queuelistingwrp {
        background: #7f5079;
        padding: 10px;
        width: 132px;
        margin-left: 9px;
        float: left;
        margin-bottom: 25px;
        color: #fff;
    }
    /* .queuelistingwrp .queuetime {
        padding: 2px 0 4px 0;
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin-top: -20px;
    } */
    /* .queuelistingwrp .queueimglist {
        background-repeat: no-repeat;
        background-size: 100%;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-top: 20px;
        margin: 0 auto;
        margin-top: 15px;
        border: 4px solid #553c57 !important;
    }
    .queuelistingwrp h3 {
        text-align: center;
        font-size: 14px;
        color: #fff;
        margin-top: 10px
    } */
    .carousel__viewport{
        overflow: inherit;
    }
    .queue-arousel .item {
        padding-top: 30px;
        width: 100%;
    }
    .queue-arousel .item > div {
        text-align: center;
        margin: 10px;
        padding: 38px 0 10px;
        background: #000333;
        color: #666;
        position: relative;
        border-radius: 5px;
        cursor: pointer;
    }
    .bk-image {
        position: absolute;
        top: -33px;
        left: 0;
        right: 0;
        border-radius: 100%;
        overflow: hidden;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255 / 55%);
    }
    .booking-time {
        color: #fff;
        font-size: 18px;
    }
    .booking-id {
        background: rgb(255 255 255 / 51%);
        color: #fff;
        font-size: 15px;
        margin: 5px 0;
        padding: 4px;
        text-align: center;
    }
    .queue-arousel .queuetime {
        padding: 2px 0 4px 0;
        text-align: center;
        color: #fff;
        font-size: 12px;
        /* margin-top: -20px; */
    }

.payment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* ensure it's above other content */
}

.payment-loader {
position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.message {
  color: #ffffff;
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
}

.emp-card {
        cursor: pointer;
        border: 1px solid #f1f1f1;
        border-radius: .5rem
    }
    .emp-card svg {
        display: none
    }
    .emp-card.active {
        border: 2px solid #6e317a;
        background: #6e317a0f
    }
    .emp-card.active svg {
        display: block;
        position: absolute;
        right: -7px;
        top: -7px;
        color: #6e317a;
        z-index: 23;
        background: #fff
    }
    .booking-vendor-img {
        background-position: 50% 50%;
        cursor: pointer;
        float: left;
        height: 60px;
        text-decoration: none solid rgb(0, 0, 238);
        text-size-adjust: 100%;
        width: 60px;
        border: 0px none rgb(0, 0, 238);
        border-radius: 100% 100% 100% 100%;
        flex: 0 0 auto;
        font: normal normal 300 normal 15px / 20px proxima-nova, sans-serif;
        margin: 0px 15px 0px 0px;
        outline: rgb(0, 0, 238) none 0px;
    }
</style>

<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>